import React, { useState } from 'react';
import MainContext from './MainContext';
import {
  signup,
  login,
  fetchAOwner,
  forgotpassowrd,
  getAllUsers,
  updateOwnerSettings,
  updateOwnerProfile,
  updateOwnerProfileImage,
  updateOwnerSalesMask,
  OwnerVarification,
  sendCodeToEmail,
  verifyCode,
} from './contextPages/AuthContexts';
import {
  fetchAllClients,
  getAllClient,
  fetchClientByNumber,
  fetchClientById,
  createClient,
  createManyClient,
  buyMembership,
  updateClient,
  sendBulkSms,
  deleteClient,
  fetchBillByClientNumber,
  fetchVisitedClinetAnalytics,
  walletPayment,
} from './contextPages/ClientContexts';
import {
  fetchAllProducts,
  createProduct,
  createManyProduct,
  updateProduct,
  deleteProduct,
  deleteManyProduct,
  addProductCount,
} from './contextPages/ProductContexts';
import {
  fetchAllMemberships,
  fetchMembershipDetail,
  createMembership,
  updateMembership,
  deleteMembership,
  createPackages,
  fetchAllPackages,
  fetchSinglePackageDetail,
  updatePackage,
  buyPackage,
} from './contextPages/MembershipContexts';
import {
  fetchAllAppointments,
  fetchAppointmentById,
  fetchStaffAppointmentsDateWise,
  createAppointment,
  updateAppointment,
  updateAppointmentStatus,
  deleteAppointment,
  assignAppointment,
  createWebAppointmentBookingLink,
  fetchAppointmentAnalytics,
} from './contextPages/AppointmentContexts';
import {
  fetchAllStaffs,
  getAllStaff,
  createStaff,
  updateStaff,
  deleteStaff,
  getStaffById,
  fetchStaffOverviewData,
  fetchAttendanceByStaffId,
  staffCollection,
  changeStaffAccountStatus,
  staffStatusUpdate,
  fetchStaffWiseWork,
  staffWorkAnalysis,
  staffOverview,
} from './contextPages/StaffContexts';
import {
  fetchAllServices,
  getAllService,
  createService,
  updateService,
  deleteService,
  createManyService,
  deleteManyService,
} from './contextPages/ServiceContexts';
import {
  fetchAllBillings,
  fetchBillAnalytics,
  fetchBillById,
  createBilling,
  deleteBilling,
  updateBill,
  clearRemainingAmount,
  fetchMemberAnalytixs,
  fetchPackageBills,
  // new
  deletePackagebill,
  getGstData,
  fetchDraftBill,
  createDraftBilling,
  fetchSingleBill,
} from './contextPages/BillingContexts';
import {
  fetchAllBranch,
  fetchParentBranch,
  getBranch,
  createBranch,
  updateBranch,
  deleteBranch,
  getFeedbackUrl,
  getBillUrl,
} from './contextPages/BranchContexts';
import { fetchAllFeedbacks, createFeedback } from './contextPages/FeedbackContexts';
import { fetchAllCategories, createCategory } from './contextPages/CategoryContext';
import { fetchAllSubscription } from './contextPages/SubscriptionsContexts';
import {
  fetchAllCoupon,
  createCoupon,
  updateCoupon,
  deleteCoupon,
} from './contextPages/CouponContexts';
import {
  fetchAllExpenses,
  fetchExpenseAnalytics,
  fetchAllExpenseType,
  createExpense,
  updateExpense,
  deleteExpense,
} from './contextPages/ExpenseContexts';
import { fetchOverviewData } from './contextPages/DashboardContexts';
import { IvrCall, fetchIvr, updateIvrDetails } from './contextPages/IvrContexts';
import { fetchNotifications } from './contextPages/NotificationContexts';
import {
  fetchAllEquiry,
  createClientEnquiry,
  updateClientEnquiry,
  deleteClientEnquiry,
} from './contextPages/ClientEnquiryContexts';

const MainState = (props) => {
  const [activeBranchId, setActiveBranchId] = useState(null);
  const [ownerData, setOwnerData] = useState({});
  const [branchData, setBranchData] = useState({});
  const [showMessage, setShowMessage] = useState({});

  return (
    <>
      <MainContext.Provider
        value={{
          activeBranchId,
          setActiveBranchId,

          setBranchData,
          branchData,

          IvrCall,
          fetchIvr,
          updateIvrDetails,

          ownerData,
          setOwnerData,

          showMessage,
          setShowMessage,

          fetchOverviewData,

          fetchAllBranch,
          fetchParentBranch,
          getBranch,
          createBranch,
          updateBranch,
          deleteBranch,
          getFeedbackUrl,
          getBillUrl,

          fetchAllCoupon,
          createCoupon,
          updateCoupon,
          deleteCoupon,

          fetchAllExpenses,
          fetchExpenseAnalytics,
          fetchAllExpenseType,
          createExpense,
          updateExpense,
          deleteExpense,

          fetchAllStaffs,
          getAllStaff,
          getStaffById,
          fetchStaffOverviewData,
          staffCollection,
          fetchAttendanceByStaffId,
          createStaff,
          updateStaff,
          deleteStaff,
          changeStaffAccountStatus,
          staffStatusUpdate,
          fetchStaffWiseWork,
          staffWorkAnalysis,
          staffOverview,

          fetchAllAppointments,
          fetchAppointmentById,
          fetchStaffAppointmentsDateWise,
          createAppointment,
          updateAppointment,
          updateAppointmentStatus,
          deleteAppointment,
          assignAppointment,
          createWebAppointmentBookingLink,
          fetchAppointmentAnalytics,

          fetchAllClients,
          getAllClient,
          fetchClientByNumber,
          fetchClientById,
          createClient,
          createManyClient,
          buyMembership,
          updateClient,
          sendBulkSms,
          deleteClient,
          fetchBillByClientNumber,
          fetchVisitedClinetAnalytics,
          walletPayment,
          
          fetchMembershipDetail,
          fetchAllMemberships,
          createMembership,
          updateMembership,
          deleteMembership,
          createPackages,
          fetchAllPackages,
          fetchSinglePackageDetail,
          updatePackage,
          buyPackage,

          fetchAllProducts,
          createProduct,
          createManyProduct,
          updateProduct,
          deleteProduct,
          deleteManyProduct,
          addProductCount,

          fetchAllServices,
          getAllService,
          createService,
          deleteService,
          createManyService,
          deleteManyService,
          updateService,

          fetchAllBillings,
          fetchBillAnalytics,
          fetchMemberAnalytixs,
          fetchBillById,
          createBilling,
          deleteBilling,
          updateBill,
          clearRemainingAmount,
          fetchPackageBills,
          // new
          deletePackagebill,
          getGstData,
          fetchDraftBill,
          createDraftBilling,
          fetchSingleBill,
         
          fetchAllFeedbacks,
          createFeedback,

          fetchAllCategories,
          createCategory,

          signup,
          login,
          fetchAOwner,
          updateOwnerSettings,
          updateOwnerProfile,
          updateOwnerProfileImage,
          updateOwnerSalesMask,
          OwnerVarification,
          getAllUsers,
          forgotpassowrd,
          sendCodeToEmail,
          verifyCode,

          fetchAllSubscription,

          fetchNotifications,

          fetchAllEquiry,
          createClientEnquiry,
          updateClientEnquiry,
          deleteClientEnquiry,
        }}
      >
        {props.children}
      </MainContext.Provider>
    </>
  );
};

export default MainState;

