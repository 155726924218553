import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress
} from '@mui/material';
import * as XLSX from 'xlsx';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
import FieldData from './FieldData';
import SearchImg from '../../assets/search.svg';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';
import BigFilter from 'src/components/inputs/BigFilter';
import { IoStar } from "react-icons/io5";
import { ClearIcon } from '@mui/x-date-pickers-pro';
import { FaDownload } from 'react-icons/fa';

const filterMenu = [
  { value: 'ratings', label: 'Ratings' },
];

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const filterRatings = [
  { value: 4, label: <>4 <IoStar style={{ marginBottom: '0.2rem' }} /> & above</> },
  { value: 3, label: <>3 <IoStar style={{ marginBottom: '0.2rem' }} /> & above</> },
];

const filterPaymentMethods = [
  { value: 'Cash', label: 'Cash' },
  { value: 'Wallet', label: 'Wallet' },
  { value: 'Credit/Debit Card', label: 'Credit/Debit Card' },
  { value: 'Gpay', label: 'GPay' },
  { value: 'Paytm', label: 'Paytm' },
  { value: 'PhonePe', label: 'PhonePe' },
];

const AllBillings = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalFilteredCount, setTotalFilteredCount] = useState(0); // Track total filtered count
  const [sortValue, setSortValue] = useState(-1);
  const [clear, setClear] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState([]);
  const [selectedGenderBy, setSelectedGenderBy] = useState([]);
  const [selectedRatingBy, setSelectedRatingBy] = useState([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
  const [billType, setBillType] = useState('Bill'); // State for bill type
  const [pageTotals, setPageTotals] = useState({ totalAmount: 0, amountPaid: 0, paidDues: 0 });
  const [otpLoading, setOtpLoading] = useState(false);

  const { fetchAllBillings, activeBranchId, ownerData, sendCodeToEmail, verifyCode, setShowMessage } = useMain();
  const [openDialog, setOpenDialog] = useState(false);
  // const [otp, setOtp] = useState('');
  const [securityCode, setSecurityCode] = useState('');

  const navigate = useNavigate();

  // useEffect(() => {
  //   getData(page, billType);
  //   // eslint-disable-next-line
  // }, [activeBranchId, billType]);

  const getData = async () => {
    const queryParams = `?limit=${limit}&page=${page}&billType=${billType}`
    + (search ? `&searchValue=${encodeURIComponent(search)}` : '')
    + (startDate ? `&startDate=${encodeURIComponent(startDate)}` : '')
    + (endDate ? `&endDate=${encodeURIComponent(endDate)}` : '')
    + (selectedSortBy.length > 0 ? `&sortBy=${selectedSortBy.join(',')}` : '')
    + (selectedGenderBy.length > 0 ? `&gender=${selectedGenderBy.join(',')}` : '')
    + (selectedRatingBy.length > 0 ? `&ratings=${selectedRatingBy.join(',')}` : '')
    + (selectedPaymentMethods.length > 0 ? `&paymentMethod=${selectedPaymentMethods.join(',')}` : '')
    + `&sortValue=${sortValue}`
    + (clear ? `&clear=${clear}` : '');

    try {
      setShowLoader(true);
      const res = await fetchAllBillings(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setData(res.data || []);
        setFilteredData(res.data || []);
        setTotalFilteredCount(res.data?.length)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      setShowLoader(false);
    }
  };

  // const applyFilters = () => {
  //   if (!data.length) return;

  //   let filtered = data.filter((item) => {
  //     let isGenderMatch = true;
  //     let isRatingMatch = true;
  //     let isNameMatch = true;
  //     let isDateMatch = true;
  //     let isPaymentMatch = true;

  //     if (selectedGenderBy.length) {
  //       isGenderMatch = selectedGenderBy.includes(item.gender);
  //     }

  //     if (selectedRatingBy.length) {
  //       isRatingMatch = selectedRatingBy.some(rating => {
  //         if (rating === 4) return item.ratings >= 4;
  //         if (rating === 3) return item.ratings >= 3 && item.ratings < 4;
  //         return false;
  //       });
  //     }

  //     if (search) {
  //       const clientName = item.clientName || '';
  //       const clientNumber = item.clientNumber || '';
  //       isNameMatch = clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
  //     }

  //     if (startDate && endDate) {
  //       const billDate = new Date(item.dateOfBilling);
  //       isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
  //     }

  //     if (selectedPaymentMethods.length) {
  //       isPaymentMatch = item.amountPaid && item.amountPaid.some(({ paymentType }) =>
  //         selectedPaymentMethods.includes(paymentType)
  //       );
  //     }

  //     return isGenderMatch && isRatingMatch && isNameMatch && isDateMatch && isPaymentMatch;
  //   });

  //   setTotalFilteredCount(filtered.length); // Update total filtered count
  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filtered = filtered.slice(startIndex, endIndex); // Paginate the filtered data

  //   setFilteredData(filtered);
  //   setShowLoader(false);
  // };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [activeBranchId, billType, limit, selectedGenderBy, selectedRatingBy, page, search, startDate, endDate, selectedSortBy, sortValue]);


  useEffect(() => {
    const totals = calculatePageTotals(filteredData);
    setPageTotals(totals);
  }, [filteredData]);

  const calculatePageTotals = (currentPageData) => {
    const totalAmount = currentPageData.reduce((acc, item) => acc + (item.amountPayable || 0), 0);

    const amountPaid = currentPageData.reduce((acc, item) => {
      if (Array.isArray(item.amountPaid)) {
        const totalPaid = item.amountPaid.reduce((sum, payment) => sum + (parseFloat(payment.amount) || 0), 0);
        return acc + totalPaid;
      }
      return acc;
    }, 0);

    const paidDues = currentPageData.reduce((acc, item) => acc + (item.paidDues || 0), 0);

    // Ensure paidDues is not NaN
    return { totalAmount, amountPaid, paidDues: isNaN(paidDues) ? 0 : paidDues };
  };


  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1); // Reset page to 1 when limit changes
  };

  const handleSortChange = (sortField, sortOrder) => {
    setSelectedSortBy(sortField);
    setSortValue(sortOrder);
    setPage(1);
  };

  // const handleOnExport = () => {
  //   // ... (Export logic remains unchanged)
  // };

  // const handleDownload = async () => {
  //   // Trigger the OTP API call first
  //   try {
  //     setShowLoader(true);
  //     const response = await sendCodeToEmail(ownerData?._id); // Pass the ownerId to the API
  //     if (response?.statusCode === 200) {
  //       setOpenDialog(true); // Open the OTP dialog box
  //       setShowMessage({
  //         message: response.message || 'The Otp has been Successfully sent to the owner email',
  //         messageType: 'success',
  //       });
  //     } else {
  //       alert('Failed to send Security Code. Please try again.');
  //     }
  //   } catch (error) {
  //     console.error('Error sending OTP:', error);
  //   } finally {
  //     setShowLoader(false);
  //   }
  // };

  // const handleSubmitOtp = async () => {
  //   // Check if the security code is empty before proceeding
  //   if (!securityCode) {
  //     alert('Please enter the security code.'); // Inform the user that the security code is required
  //     return; // Exit the function early without submitting
  //   }

  //   // Handle OTP submission logic here (send to server for verification)
  //   try {
  //     setLoading(true);
  //     // Call the function to verify the OTP or security code
  //     const response = await verifyCode(ownerData?._id, { securityCode });

  //     // Check if the response indicates an error
  //     if (response?.statusCode === 200) {
  //       setShowMessage({
  //         message: response.message || ' The Otp has been successfully submitted ',
  //         messageType: 'success',
  //       })
  //     }

  //     // If the OTP is valid, proceed with the Excel file download
  //     handleOnExport();
  //     setOpenDialog(false); // Close the OTP dialog after successful OTP validation
  //   } catch (error) {
  //     console.error('Error verifying OTP:', error);
  //     alert('An error occurred while verifying the OTP. Please try again.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDownload = async () => {
    try {
      setOtpLoading(true);
      const response = await sendCodeToEmail(ownerData?._id);
      if (response?.statusCode === 200) {
        setOpenDialog(true);
        setShowMessage({
          message: response.message || 'The Otp has been Successfully sent to the owner email',
          messageType: 'success',
        });
      } else {
        alert('Failed to send Security Code. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    } finally {
      setOtpLoading(false);
    }
  };

  const handleSubmitOtp = async () => {
    if (!securityCode) {
      alert('Please enter the security code.');
      return;
    }

    try {
      setOtpLoading(true);
      const response = await verifyCode(ownerData?._id, { securityCode });

      if (response?.statusCode === 200) {
        setShowMessage({
          message: 'The security code has been successfully verified.',
          messageType: 'success',
        });
        handleOnExport();
        setOpenDialog(false);
      } else if (response?.statusCode === 400) {
        setShowMessage({
          message: response.message || 'The security code does not match. Please try again.',
          messageType: 'error',
        });
      } else {
        setShowMessage({
          message: 'An unexpected error occurred. Please try again.',
          messageType: 'error',
        });
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('An error occurred while verifying the security code. Please try again.');
    } finally {
      setOtpLoading(false);
    }
  };



  const handleOnExport = () => {
    if (!Array.isArray(data)) {
      console.error('Data is not an array or is undefined');
      return;
    }

    const selectedFields = [
      'clientName', 'clientNumber', 'gender', 'useMembership', 'dateOfBilling', 'timeOfBilling',
      'subTotal', 'totalAmount', 'amountPayable', 'paidDues', 'advancedGiven', 'preAdvanceUsed',
      'ratings',
    ];

    const flattenedData = data.map((item) => {
      const flattenedItem = {};
      selectedFields.forEach((field) => {
        if (item.hasOwnProperty(field)) {
          flattenedItem[field] = item[field];
        }
      });
      if (Array.isArray(item.amountPaid)) {
        flattenedItem.amountPaid = item.amountPaid.reduce((total, item) => total + parseFloat(item.amount), 0);
      }
      if (Array.isArray(item.services)) {
        flattenedItem.serviceName = item.services.map(serviceItem => serviceItem.serviceName).join(', ');
        flattenedItem.serviceProviders = item.services.map(serviceItem => serviceItem.serviceProvider?.name || '').join(', ');
      }
      if (Array.isArray(item.products)) {
        flattenedItem.productName = item.products.map(productItem => productItem.productName).join(', ');
        flattenedItem.productProvider = item.products.map(productItem => productItem.providerStaff?.name || '').join(', ');
      }
      return flattenedItem;
    });

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, ws, 'Bills');
    XLSX.writeFile(wb, 'Bills.xlsx');
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDateRangeSelected = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsModalOpen(false);
    setClear(true);
    // applyFilters();
    getData()
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1); // Reset to the first page
    setSearch('');
    setSelectedSortBy([]);
    setSelectedGenderBy([]);
    setSelectedRatingBy([]);
    setSortValue(-1);
    setEndDate('');
    setStartDate('');
    setClear(false);
    // applyFilters();
  };

  const getPaymentMethods = () => {
    if (billType === 'Wallet') {
      return filterPaymentMethods.filter(method => method.value !== 'Wallet');
    }
    return filterPaymentMethods;
  };

  return (
    <PageContainer title="Manage Billings" description="this contains all products">
      <DashboardCard2 title="Manage Billings">
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '14rem',
          alignItems: 'center',
        }}>
          <div style={{ marginBottom: '1.7rem', flex: '1 1 150px', }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
                // width: '100%', // Ensure full width
                width: '43%',
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              {[10, 20, 50].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div style={{ flex: '1 1 150px' }}>
            <Button
              variant="outlined"
              sx={{
                color: 'black',
                height: '40px',
                width: startDate && endDate ? '100%' : '60%', // Use full width when dates are selected
                marginTop: '0.4rem',
                marginBottom: '0.3rem',
                marginLeft: startDate && endDate ? '-19rem' : '-19rem', // Adjust margin based on date selection
                display: 'flex',
                justifyContent: startDate && endDate ? 'space-between' : 'center',
                padding: '0 8px',
                whiteSpace: 'nowrap', // Prevent text wrapping
                overflow: 'hidden', // Hide overflow
                textOverflow: 'ellipsis', // Add ellipsis for overflow text
              }}
              onClick={openModal}
            >
              {startDate && endDate ? (
                <>
                  <span>{`${startDate} to ${endDate}`}</span>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setStartDate('');
                      setEndDate('');
                      setClear(false);
                      getData();
                    }}
                    sx={{
                      padding: 0,
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <ClearIcon style={{ color: '#000', height: '1rem' }} />
                  </IconButton>
                </>
              ) : (
                'Choose Date'
              )}
            </Button>
          </div>


          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
            flex: '1 1 150px',
            maxWidth: '600px',
          }}>
            <div style={{ flex: 1 }} onClick={() => document.getElementById('search-input').focus()}>
              <InputLabel>Search</InputLabel>
              <div className="search-input" style={{ marginBottom: "1.5rem", display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <img src={SearchImg} alt="" style={{ verticalAlign: 'middle', }} />
                <input
                  id="search-input"
                  style={{
                    flex: 1,
                    padding: '4px 2px',
                    borderRadius: '7px',
                    width: '100%', // Ensure full width
                  }}
                  type="text"
                  placeholder=""
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                      setClear(false);
                    }}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{
                      // color: '#ff4d4d',
                      color: '#000',
                      // color: '#A2A3AE',
                      marginRight: '-0.2rem', marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
            </div>
            <div
              style={{
                width: '63px',
                marginLeft: '-5rem',
                marginTop: '0.1rem',
                pointerEvents: otpLoading ? 'none' : 'auto', // Disable clicks when otpLoading is true
              }}
            >
              <Button
                variant="contained"
                color="primary"
                title="Download Bills"
                sx={{
                  height: '40px',
                  width: '100%',
                  padding: "0.5rem",
                  '&:hover': { background: '#6174DD' },
                }}
                onClick={handleDownload}
                disabled={otpLoading} // Disable button only when otpLoading is true
              >
                {/* FaDownload icon always visible; CircularProgress only appears when otpLoading is true */}
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <FaDownload style={{ height: "1.6rem", fontSize: "1.2rem" }} />
                  {otpLoading && <CircularProgress size={24} />}
                </Box>
              </Button>
            </div>
          </div>
        </div>
        {/* OTP Dialog */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Enter Security Code</DialogTitle>
          <DialogContent>
            <TextField
              label="Security Code"
              variant="outlined"
              fullWidth
              value={securityCode}
              onChange={(e) => setSecurityCode(e.target.value)}
              autoFocus
              style={{ marginTop: '0.5rem', width: '15rem' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmitOtp}
              color="primary"
              disabled={otpLoading}
            // startIcon={otpLoading && <showLoader />} // Show loader when otpLoading is true
            >
              {otpLoading ? 'Verifying...' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // Adjusted for responsiveness
          alignItems: 'center',
          justifyContent: 'space-between', // Use space-between for even distribution
          gap: '2rem',
        }}>
          <div style={{ gridColumn: "span 1", display: 'flex', justifyContent: 'end', width: '150%' }}>
            <ToggleButtonGroup
              value={billType}
              exclusive
              sx={{
                borderRadius: '30px',
                height: '40px',
                backgroundColor: '#f0f0f0',
                width: '1000px', // Adjusted width
                border: "1px solid #cfcece",
                marginLeft: '15rem'
              }}
              onChange={(event, newBillType) => {
                if (newBillType) {
                  setBillType(newBillType);
                  setPage(1);
                }
              }}
            >
              <ToggleButton
                value="Bill"
                sx={{
                  color: '#000',
                  backgroundColor: '#fafafa',
                  flex: 1,
                  borderRadius: '30px',
                  border: '1px solid transparent', // Default border
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    border: 'none', // Remove border when selected
                    '&:hover': {
                      backgroundColor: '#6174DD',
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
              >
                Bills
              </ToggleButton>

              {/* Vertical divider between Bills and Package Bills */}
              {billType !== "PackageBills" && billType !== "Bill" && (
                <div style={{ height: '39px', width: '3px', backgroundColor: '#cfcece' }} />
              )}

              <ToggleButton
                value="PackageBills"
                sx={{
                  color: '#000',
                  backgroundColor: '#fafafa',
                  flex: 1,
                  borderRadius: '30px',
                  border: '1px solid transparent',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    border: 'none',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
                onClick={() => navigate('/PackageAllBill')}
              >
                Package Bills
              </ToggleButton>

              {/* Vertical divider between Package Bills and Draft Bills */}
              {billType !== "AllDraftBills" && (
                <div style={{ height: '39px', width: '3px', backgroundColor: '#cfcece' }} />
              )}

              <ToggleButton
                value="AllDraftBills"
                sx={{
                  color: '#000',
                  backgroundColor: '#fafafa',
                  flex: 1,
                  borderRadius: '30px',
                  border: '1px solid transparent',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    border: 'none',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
                onClick={() => navigate('/AllDraftBills')}
              >
                Draft Bills
              </ToggleButton>

              {/* Vertical divider between Draft Bills and Wallet */}
              {billType !== "Wallet" && (
                <div style={{ height: '39px', width: '3px', backgroundColor: '#cfcece' }} />
              )}

              <ToggleButton
                value="Wallet"
                sx={{
                  color: '#000',
                  backgroundColor: '#fafafa',
                  flex: 1,
                  borderRadius: '30px',
                  border: '1px solid transparent',
                  '&.Mui-selected': {
                    backgroundColor: '#6174DD',
                    color: '#fff',
                    border: 'none',
                    '&:hover': {
                      backgroundColor: '#6174DD',
                    },
                  },
                  '&:not(.Mui-selected)': {
                    '&:hover': {
                      backgroundColor: '#d0d0d0',
                    },
                  },
                }}
              >
                Wallet
              </ToggleButton>
            </ToggleButtonGroup>

          </div>

          <div style={{ display: 'flex', marginTop: '-1.8rem', width: "51%", marginLeft: '17rem' }}>
            <BigFilter
              options={[
                { title: 'Gender', items: filterGenderWise },
                { title: 'Rate By', items: filterRatings },
                { title: 'Payment Method', items: getPaymentMethods() },
              ]}
              value={[...selectedGenderBy, ...selectedSortBy, ...selectedRatingBy, ...selectedPaymentMethods]}
              onChange={(selectedValues) => {
                setSelectedGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
                setSelectedRatingBy(selectedValues.filter(val => filterRatings.some(opt => opt.value === val)));
                setSelectedPaymentMethods(selectedValues.filter(val => getPaymentMethods().some(opt => opt.value === val)));
              }}
              placeholder="Filters"
            />
          </div>
        </div>
        {showLoader ? (
          <ShowLoader />
        ) : filteredData && filteredData.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <FieldData data={filteredData} setData={setData} getData={getData} billType={billType} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          // 'No data found'
          <div style={{ marginTop: "2rem" }} variant="h6" align="center">No data found</div>
        )}

        
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Pagination
              count={page+1} // Use total filtered count for pagination
              page={page}
              color="primary"
              onChange={(e, page) => {
                setPage(page);
                getData();// Reapply filters to update displayed data
              }}
            />
          </Box>
        

        <Box
          display="flex"
          flexDirection="column"
          style={{
            width: '100%',
            marginLeft: '0.5rem',
            marginTop: '1rem',
            padding: '1rem',
            // backgroundColor: '#D6D6C8', // Light cream color
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
            borderRadius: '8px' // Rounded corners for a softer look
          }}
        >
          <Typography variant="h6" fontWeight="600" gutterBottom>
            Amount as per Page
          </Typography>

          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" fontWeight="600">
              <strong>Total Amount:</strong> ₹ {pageTotals.totalAmount}
            </Typography>
            <Typography variant="subtitle1" fontWeight="600">
              <strong>Total Paid:</strong> ₹ {pageTotals.amountPaid}
            </Typography>
            <Typography variant="subtitle1" fontWeight="600">
              <strong>Total Pending:</strong> ₹ {pageTotals.paidDues}
            </Typography>
          </Box>
        </Box>


        <DateRangeInputs
          isOpen={isModalOpen}
          onClose={closeModal}
          onDateRangeSelected={handleDateRangeSelected}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
        />
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllBillings;