import { baseUrl, headers, jwtFormDataHeaders, jwtHeaders, logoutUtil } from '../common/data';

export const signup = async (data) => {
  const response = await fetch(`${baseUrl}/admin/signup`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
    redirect: 'follow',
  });

  if (response?.status === 401) {
    logoutUtil();
  }
  const resData = await response?.json();

  return resData;
};

export const login = async (data) => {
  const response = await fetch(`${baseUrl}/api/owner/loginowner`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
    redirect: 'follow',
  });
  if (response?.status === 401) {
    logoutUtil();
  }
  const resData = await response?.json();

  return resData;
};

export const forgotpassowrd = async (data) => {
  const response = await fetch(`${baseUrl}/api/owner/forgotpassword`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
    redirect: 'follow',
  });
  const resData = await response?.json();

  return resData;
};

export const fetchAOwner = async (id) => {
  const response = await fetch(`${baseUrl}/api/owner/getaowner/${id}`, {
    method: 'GET',
    headers: jwtHeaders(),
  });
  const resData = await response?.json();
  return resData
};

export const getAllUsers = async () => {
  const response = await fetch(`${baseUrl}/admin/user/all`, {
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const updateOwnerSettings = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/owner/updatenumbermasking/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers:jwtHeaders()
  });
  const resData = await response.json();
  return resData;
};

export const updateOwnerSalesMask = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/owner/salesmasking/${id}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers:jwtHeaders()
  });
  const resData = await response.json();
  return resData;
};

export const OwnerVarification = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/owner/ownervarification/${id}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers:jwtHeaders()
  });
  const resData = await response.json();
  return resData;
};

export const updateOwnerProfile = async (ownerId, data) => {
  const response = await fetch(`${baseUrl}/api/owner/updateowner/${ownerId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers:jwtHeaders()
  });
  const resData = await response.json();
  return resData;
};

export const updateOwnerProfileImage = async (ownerId, data) => {
  const response = await fetch(`${baseUrl}/api/owner/updatelogo/${ownerId}`, {
    method: "PATCH",
    body: data,
    headers:jwtFormDataHeaders()
  });
  const resData = await response.json();
  return resData;
};


export const sendOtpProtectedRoute = async (ownerId) => {
  const response = await fetch(`${baseUrl}/api/otp/sendotp/${ownerId}`, {
    method: 'POST',
    headers,
  });
  const resData = await response.json();
  return resData;
};

// export const verifyOtpProtectedRoute = async (ownerId, data) => {
//   const response = await fetch(`${baseUrl}/api/otp/verifyotp/${ownerId}`, {
//     method: 'POST',
//     body: JSON.stringify(data),
//     headers,
//   });
//   const responseData = await response.json();
//   return responseData;
// };


export const sendCodeToEmail = async (ownerId) => {
  const response = await fetch(`${baseUrl}/api/owner/sendcodetoemail?ownerId=${ownerId}`, {
    method: 'GET',
    // headers,
    headers:jwtHeaders()
  });
  const responseData = await response.json();
  return responseData;
};

export const verifyCode = async (ownerId,data) => {
  // const response = await fetch(`${baseUrl}/api/owner/sendcodetoemail?ownerId=${ownerId}`, {
  const response = await fetch(`${baseUrl}/api/owner/codeVarification?ownerId=${ownerId}`, {
    method: 'POST',
    // headers,
    headers:jwtHeaders(),
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  return responseData;
};
